import { Component } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostPublicationRequest } from 'src/app/models/publication.models';
import { PublicationService } from '../services/publication.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { ErrorModalComponent } from 'src/app/common/dialogs/error-dialog/error-modal.component';
import { HttpErrorResponse } from '@angular/common/http';

interface Journal {
  title: string;
  issn: string;
  description: string;
}

interface Conference {
  title: string;
  url: string;
  description: string;
}


@Component({
  selector: 'arumid-submit-publication-page',
  templateUrl: './submitPublication.component.html',
  styleUrls: ['./submitPublication.component.scss']
})

export class SubmitPublicationComponent {
  constructor(private formBuilder: FormBuilder, private publicationService: PublicationService, private dialog: MatDialog){}
  maxChars = 500;
  publicationForm: FormGroup = null;
  isChecked: boolean = false;

  description = "";

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tags: string[] = [];

  ngOnInit() {
    this.initForm();
    this.getCorrectValidator();
  }

  onSubmit() {
    this.publicationService.postAddPublication(this.generateRequest()).toPromise()
      .then((response: any) => {
        const dialogRef = this.dialog.open(SuccessModalComponent, {
          width: '550px'
        })

        dialogRef.afterClosed().subscribe(() => {
          this.isChecked = false;
          this.tags = [];
          this.publicationForm.reset();
        })

      })
      .catch((error: HttpErrorResponse) => {
        let errorMessage = error.error.message;
        if(error.status == 401){
          errorMessage = "Please login to submit request"
        }
        this.dialog.open(ErrorModalComponent, {
          width: '550px',
          data: { message: errorMessage}
        })
      })

  }

  generateRequest(): PostPublicationRequest{
    let request: PostPublicationRequest;
    const title = this.publicationForm.get('title').value
    const url = this.publicationForm.get('url').value
    const issn = this.publicationForm.get('issn').value
    const description = this.publicationForm.get('description').value

    if(this.isChecked){
      request = {
        title: title,
        url: url,
        description: description,
        tags: this.tags,
        type: 'C'
      }
    }else {
      request = {
        title: title,
        issn: issn,
        description: description,
        tags: this.tags,
        type: 'J'
      }
    }

    return request;
  }

  initForm() {
    this.publicationForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required, Validators.maxLength(500)]],
      issn: [null, []],
      url: [null, []]
    })
  }

  getCorrectValidator() {
    const issn = this.publicationForm.get('issn');
    const url =  this.publicationForm.get('url');

    if(!this.isChecked){
      issn.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8)]);
      url.setValidators(null)
    }else{
      issn.setValidators(null);
      url.setValidators([Validators.required])
    }

    issn.updateValueAndValidity();
    url.updateValueAndValidity();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.tags.push(value);
    }

    event.chipInput!.clear();
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  edit(tag: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(tag);
      return;
    }

    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags[index] = value;
    }
  }
}

