<div class="registerCard">
  <h1>User Register</h1>
  <div class="text">For integrity, we are only accepting specific domains which have been specified in the <a class="link" href="/aboutus#email_domain_about">about us</a> page.
    If you would like to add a new domain to the list please contact <span class="emailDomain">domains@arumid.org</span>
  </div>
  <div>
    <div *ngIf="postErrors" class="error">
      <ul *ngIf="postErrors.email">
        <li *ngFor="let error of postErrors.email">{{error}}</li>
      </ul>
      <ul *ngIf="postErrors.first_name">
        <li *ngFor="let error of postErrors.firstName">{{error}}</li>
      </ul>
      <ul *ngIf="postErrors.last_name">
        <li *ngFor="let error of postErrors.last_name">{{error}}</li>
      </ul>
      <ul *ngIf="postErrors.password">
        <li *ngFor="let error of postErrors.password">{{error}}</li>
      </ul>
      <ul *ngIf="postErrors.country">
        <li *ngFor="let error of postErrors.country">{{error}}</li>
      </ul>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form">
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" autocomplete="off" formControlName="email">
      </mat-form-field>
      <mat-form-field class="formField">
        <mat-label for="first_name">First Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="first_name">
      </mat-form-field>
      <mat-form-field class="formField">
        <mat-label for="last_name">Last Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="last_name">
      </mat-form-field>
      <mat-form-field class="formField">
        <mat-label for="password">Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="password">
      </mat-form-field>
      <mat-form-field class="formField">
        <mat-label for="password_confirmation">Password Confirm</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="password_confirmation">
      </mat-form-field>
      <div *ngIf="!(registerForm.getError('passwordValid') == null)"class="error">
        <ul>
          <li>Passwords do not match.</li>
        </ul>
      </div>
      <mat-form-field class="formField">
        <mat-label for="country">Country</mat-label>
        <mat-select formControlName="country">
          <div *ngFor="let country of COUNTRIES">
            <mat-option [value]="country">
              {{country}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="terms_and_conditions">Accept <a href="terms-and-conditions">Terms and Conditions</a></mat-checkbox>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!registerForm.valid">Register</button>
    </form>
  </div>
</div>
