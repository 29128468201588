import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { AuthStore } from "../auth/stores/auth.store";
import { PostPublicationRequest } from "src/app/models/publication.models";


const requests = {
  getDistributedCountries: `${environment.apiBase}/publications/countries`,
  postPublication: `${environment.apiBase}/publications`,
  getApprovedJournalsSearch: (searchData: string) => `${environment.apiBase}/publications/search/approved/journals/APPROVED?search=${searchData}`,
  getApprovedConferencesSearch: (searchData: string) => `${environment.apiBase}/publications/search/approved/conferences/APPROVED?search=${searchData}`,
  postRankings: `${environment.apiBase}/publications/ranking`,
  getPublicationRankingByCountry: (country: string) => `${environment.apiBase}/ranking/publications?country=${country}`,
  getPublicationById: (id: Number) => `${environment.apiBase}/publications/${id}`
}

@Injectable({
  providedIn: 'root'
})
export class PublicationService {
  constructor(private http: HttpClient, private authStore: AuthStore) {
  }

  getDistributedCountries(): Observable<any> {
    return this.http.get(requests.getDistributedCountries, {headers: this.authStore.getHeader()});
  }

  postAddPublication(data: PostPublicationRequest): Observable<any> {
    return this.http.post(requests.postPublication, data, {headers: this.authStore.getHeader()});
  }

  getApprovedJournalsSearch(searchData: string): Observable<any> {
    return this.http.get<any>(requests.getApprovedJournalsSearch(searchData), {headers: this.authStore.getHeader()});
  }

  getApprovedConferencesSearch(searchData: string): Observable<any> {
    return this.http.get<any>(requests.getApprovedConferencesSearch(searchData), {headers: this.authStore.getHeader()});
  }

  postRankings(rankings: any): Observable<any> {
    return this.http.post<any>(requests.postRankings, rankings, {headers: this.authStore.getHeader()});
  }

  getPublicationRankingByCountry(country: string): Observable<any> {
    return this.http.get<any>(requests.getPublicationRankingByCountry(country), {headers: this.authStore.getHeader()});
  }
  getPublicationById (id: Number): Observable<any> {
  return this.http.get<any>(requests.getPublicationById(id), { headers: this.authStore.getHeader() });
}
}
