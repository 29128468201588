import { Component } from '@angular/core';

@Component({
  selector: 'arumid-login-required',
  templateUrl: './login-required.component.html',
  styleUrls: ['./login-required.component.scss']
})
export class LoginRequiredComponent {

}
