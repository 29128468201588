import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-terms',
    templateUrl: './terms-page.component.html',
    styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {

    constructor() { }

    ngOnInit() { }

}