<div class="container">
    <h1>Terms And Conditions</h1>
    <p>
        This website is operated and maintained by Australasian Association For Information 
        and Communication Technology (AAICT) ABN Number 81586704541 registered 
        address 8 Tarlee Court, Aspendale Victoria 3195 referred to as “our”, “us” 
        and “we” on this website. 
    </p>
    <p>
        Your use of this website is subject to these Terms and Conditions, 
        the terms of our online Privacy Statement and any other laws or 
        regulations which apply to this website. If you do not accept these, 
        you must refrain from using this website. Periodically, 
        AAICT will add or remove member information, events, forums, products and services without notice. 
    </p>
    <p>
        Your continued use of this website is subject to your accepting that 
        the information displayed may be changed without notice. 
    </p>
    <p>
        Reference to “website” relates to the whole, or any part of the web pages 
        located on this website including, but not limited to, its design, 
        underlying programming code, text, sound, graphics, animated elements or any other content. 
    </p>
    <h3>
        Subscription
    </h3>
    <p>
        Use of this website does not imply any membership to AAICT nor to any 
        organisation or other body listed on the website. 
        If you have any questions regarding subscription contact info@aaict.org.au 
    </p>
    <h3>Refund Policy </h3>
    <p>
        The AAICT refund policy is to provide refunds for products/services 
        according to the refund policy details included in signed agreements 
        between AAICT and clients. For more information about the refund policy 
        contact info@aaict.org.au 
    </p>
    <h3>
        Intellectual Property 
    </h3>
    <p>
        All logos, images, graphics, text, designs and recordings contained on 
        this website, hereafter referred to as “material”, is owned by either 
        AAICT or third party associated trading entities with respect to AAICT 
        Partners and Associate Members. Where an image or other material is 
        displayed on this website incorrectly contact info@aaict.org.au and the 
        image or other material will be removed.  
    </p>
    <p>
        Intellectual property rights are protected by Australian law and no 
        unauthorised reproduction of material contained on this website is 
        permitted. For authorisation to use or make reference to material 
        on this website, please e-mail info@aaict.org.au to 
        detail the nature of your request. 
    </p>
    <h3>
        Third party websites 
    </h3>
    <p>
        The Agama Group website contains links to third party websites due to 
        the nature of their business or non-business relationship with Agama Group. 
    </p>
    <p>
        Although Agama Group staff have met or contacted with representatives from 
        these companies to discuss the suitability of their product/service for use, 
        they cannot warrant their fitness for a particular purpose. You should, 
        therefore, conduct due diligence prior to proceeding with a 
        product/service purchase. 
    </p>
    <p>
        Agama Group staff and/or contractors are also not able to endorse the 
        currency of information contained on third party websites and 
        recommends that you contact the relevant company directly to assess 
        the information. 
    </p>
    <h3>Data Transmission</h3>
    <p>
        Given the ‘open source’ nature of the World Wide Web, Agama Group cannot 
        warrant that any data you transmit via this website is totally secure; 
        members, therefore, transmit data at their own risk. 
    </p>
    <p>
        Agama Group will take all practical steps to protect this information 
        once it is received so as its security can be maintained. 
    </p>
    <p>
        If members become aware of possible concerns with regard to the security 
        of their personal information they should contact Agama Group 
        immediately on (03) 9016 3056. 
    </p>
    <h3>Disclaimer</h3>
    <p>
        The content of this website is provided for information purposes only. 
        No claim is made as to the accuracy, currency or completeness 
        of its content at any time. 
    </p>
    <p>
        This website and the information it contains is provided for general guidance. 
        Whilst the information on this website is intended to be of general assistance, 
        it is not intended as providing professional advice to any person or 
        organisation. You should make your own inquiries as to the appropriateness 
        and suitability of the information on this site for your particular circumstances. 
    </p>
    <p>
        The views expressed by contributors to the Agama Group website 
        (i.e. third party Principal and Corporate Partners and others) 
        are not necessarily endorsed by Agama Group employees, 
        including authors, consultants and editors and may not reflect either 
        the policies or practices of Agama Group. 
    </p>
    <p>
        Additionally, although we have made every effort to ensure that the 
        material provided in downloads is complete, current, reliable, 
        free from error and virus contamination, we do not warrant or make 
        any representation about the accuracy, adequacy or completeness of the 
        information or whether the download has been corrupted or tainted by viruses. 
    </p>
</div>