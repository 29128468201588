import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/admin/services/admin.service';
import { PublicationService } from '../services/publication.service';
import {Country, Publication} from 'src/app/models/publication.models';
import { AuthStore } from '../auth/stores/auth.store';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { PublicationdialogComponent } from '../dialogs/publicationdialog/publicationdialog.component';
import { GetRankingsResponse, Rank } from 'src/app/models/ranking.models';



@Component({
  selector: 'arumid-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomePageComponent implements OnInit{
  totalJournals = 0;
  totalConferences = 0;
  activeUsers = 0;
  countries: Country[];
  selected = ""
  dialogOpen: boolean = false;


  journalRankings: Rank[] = [];
  conferenceRankings: Rank[] = [];

  countrySelectForm: FormGroup;
  selectedPublication: Publication;
  private subscription = new Subscription();
  constructor(private authStore: AuthStore,
              private adminService: AdminService,
              private publicationService: PublicationService,
              private _router: Router,
              private formBuilder: FormBuilder,
              private dialog: MatDialog) {}
  ngOnInit(){
    this.getAnalytics();
    this.getCountries();
    this.getSelectedRankings('');
    this.initCountrySelectForm();
    this.initSubscriptions();
  }

  initCountrySelectForm(): void {
    this.countrySelectForm = this.formBuilder.group({
      country: ['']
    });
  }

  initSubscriptions() {
    this.subscription.add(
      this.countrySelectForm.get('country').valueChanges.subscribe(
        (country: string) => {
          if(country == null) {
            this.getSelectedRankings('');
          } else {
            this.getSelectedRankings(country);
          }
        }
      )
    )
  }
  getAnalytics(): void{
    this.adminService.getTotalJournals().toPromise()
      .then((response: any) => {
        this.totalJournals = response.totalJournals;
      })
      .catch((error) => {
        console.log(error);
      });

    this.adminService.getTotalConferences().toPromise()
      .then((response: any) => {
        this.totalConferences = response.totalConferences;
      })
      .catch((error) => {
        console.log(error)
      });

    this.adminService.getTotalActiveUsers().toPromise()
      .then((response: any) => {
        this.activeUsers = response.totalActiveUsers
      });
  }

  getCountries(): void {
    this.publicationService.getDistributedCountries().toPromise()
      .then((response: Country[]) => {
        this.countries = response;
      })
  }

  onClickPublicationPage(): void  {
    this._router.navigate(['/submit-publication-page']);
  }

  onClickRankingPage() : void {
    this._router.navigate(['/submit-ranking-page'])
  }

  getSelectedRankings(country: string){
    this.publicationService.getPublicationRankingByCountry(country).toPromise()
      .then((response: GetRankingsResponse) => {
        this.journalRankings = response.journals;
        this.journalRankings = this.journalRankings.slice(0,10);
        this.conferenceRankings = response.conferences;
        this.conferenceRankings = this.conferenceRankings.slice(0, 10);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  openDialog(rank: Rank) {
    this.publicationService.getPublicationById(rank.publication_id).subscribe((data: any) => {
      console.log(data.data)
      const dialogRef = this.dialog.open(PublicationdialogComponent, {
        width: '550px',
        data: {publication: data.data, rank: rank.country_rank}
      });
    });
  }
}
