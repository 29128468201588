import { Injectable } from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "src/app/models/user.models";
import { Token } from "src/app/models/auth.models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  private loggedInUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private FIFTEEN_MINUTES: number = 900000;
  constructor(private _router: Router, private http: HttpClient) {
    this.checkAuth();
    setInterval(this.checkAuth, this.FIFTEEN_MINUTES);
  }
  setLoggedInUser = (entity: User):void => this.loggedInUser$.next(entity);
  getLoggedInUser = (): BehaviorSubject<User> => this.loggedInUser$;


  logoutUser = (): void => {
    this.http.get(`${environment.apiBase}/user/logout`, {headers: this.getHeader()}).subscribe(response => {
      this._router.navigate(['/']);
      this.setLoggedInUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('remember');
    })
  }

  getHeader() {
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json; charset=utf-8');
    header.append('Accept', 'application/json')

    const token = JSON.parse(localStorage.getItem('token'));

    if (token) {
      header = header.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token')).token}`);
    }

    return header;
  }

  checkAuth() {
    const token: Token = JSON.parse(localStorage.getItem('token'));
    //check if a stored token exists
    if(!token) {
      this.setLoggedInUser(null);
      return;
    }

    //check if stored token is not expired
    const expiry: Date = new Date(token.expiry);
    if(expiry < new Date()) {
      this.logoutUser();
      return;
    }

    //authenticate the token with the backend
    this.http.get(`${environment.apiBase}/auth`, {headers: this.getHeader()}).toPromise()
      .then((response: any) => {
        if(this.getLoggedInUser().value === null) {
          this.setLoggedInUser(response.data)
        } else {
          if(this.getLoggedInUser().value.id !== response.data.id) {
            this.logoutUser();
            return;
          }
        }
      })
      .catch((error) => {
        this.logoutUser();
        return;
      })
  }

  rememberMe() {
    const remember: boolean = JSON.parse(localStorage.getItem('remember'))

    if(!remember){
      this.logoutUser()
    }
  }

  refreshUser() {
    this.http.get(`${environment.apiBase}/auth`, {headers: this.getHeader()}).toPromise()
      .then((response: any) => {
        this.setLoggedInUser(response.data);
      })
  }
}
