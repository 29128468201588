import { Component } from '@angular/core';

@Component({
  selector: 'arumid-header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderAdminComponent {

}
