import { Component } from '@angular/core';

@Component({
  selector: 'arumid-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent {

}
