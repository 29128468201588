<body>
<div class="sideNav">
  <div class="navbar">
    <div class="sideNavTitle">ARUMID</div>
    <a class="item" routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{exact: true}">
      Home
    </a>
    <a class="item" routerLinkActive="active" routerLink="/admin/dashboard" [routerLinkActiveOptions]="{exact: true}">
      Dashboard
    </a>
    <a class="item" routerLinkActive="active" routerLink="/admin/publications" [routerLinkActiveOptions]="{exact: true}">
      Publications
    </a>
    <div class="item" routerLinkActive="active" routerLink="/admin/email-domains" [routerLinkActiveOptions]="{exact: true}">
      Domains
    </div>
    <a class="item" routerLinkActive="active" routerLink="/admin/user-search" [routerLinkActiveOptions]="{exact: true}">
      User Search
    </a>

  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
</body>

