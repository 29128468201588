<div class="modal-container">
  <h1 mat-dialog-title>Are You sure?</h1>
  <div mat-dialog-content class="modal-container-input">
    <p>{{data.phrase}}</p>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-stroked-button (click)="onNoClick()">No Thanks</button>
    <button mat-stroked-button color="warn" (click)="onDelete()" mat-dialog-close cdkFocusInitial>Delete</button>
  </div>
</div>
