import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { ChangePasswordRequest } from "src/app/models/auth.models";
import { AuthStore } from "../auth/stores/auth.store";

const authRequests = {
  postUpdatePassword: `${environment.apiBase}/change-password`
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient, private authStore: AuthStore) {
  }

  postUpdatePassword(data: ChangePasswordRequest): Observable<any> {
    return this.http.post<any>(authRequests.postUpdatePassword, data, {headers: this.authStore.getHeader()});
  }
}
