import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, timer } from "rxjs";
import {AuthStore} from "../stores/auth.store";
import {User} from "../../../models/user.models";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authStore: AuthStore) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //Guard for regular user.
    return timer(100).toPromise().then(() => {
      const user: User = this.authStore.getLoggedInUser().value;
      if (!user) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    });
  }
}
