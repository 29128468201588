<h2>Search for Publications</h2>

<form [formGroup]="publicationSearchForm" (ngSubmit)="onSubmit()" class="form">
  <mat-form-field class="formField">
    <mat-label>Search by Title, ISSN, URL or tags</mat-label>
    <input matInput type="text" autocomplete="off" formControlName="searchData">
  </mat-form-field>
  <div class="flex">
    <button class="search-button" mat-flat-button color=primary type="submit" [disabled]="!publicationSearchForm.valid">Search</button>
  </div>
</form>

<div *ngIf="postErrors" class="error">
  <div>{{postErrors}}</div>
</div>

<div *ngIf="publications">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 publicationTable">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Title </th>
      <td mat-cell *matCellDef="let publication"> {{publication.title}} </td>
    </ng-container>

    <ng-container matColumnDef="rank" class="center">
      <th mat-header-cell *matHeaderCellDef> Rank </th>
      <td mat-cell *matCellDef="let publication"> {{publication.rank}} </td>
    </ng-container>

    <ng-container matColumnDef="type"  class="center">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let publication"> {{publication.type == 'J' ? 'Journal' : 'Conference'}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)" class="data-row"></tr>
  </table>
</div>

<div *ngIf="pagination" class="pagination-component">
  <ul *ngFor="let page of pagination" class="pagination">
    <li *ngIf="page.url" (click)="getPage(page.url)" class="pagination-item {{page.active}}">
      <a>{{page.label}}</a>
    </li>
  </ul>
</div>
