import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import { AuthStore } from "../auth/stores/auth.store";


@Injectable({
    providedIn: 'root'
  })
  export class UserService {
    private apiUrl = `${environment.apiBase}/user`;

    constructor(private http: HttpClient, private authStore: AuthStore) {}

    public deleteCurrentUser(): Observable<any> {
      return this.http.delete<any>(this.apiUrl, { headers: this.authStore.getHeader() });
    }
  }
