import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import { AuthStore } from "../auth/stores/auth.store";
import { ContactUs, Pagination } from "src/app/models/common.models";
import { environment } from "src/environments/environment";

const apis = {
  contactUs: `${environment.apiBase}/contactus`
}

@Injectable({
  providedIn: 'root'
})

export class CommonApiService {

  constructor(private http: HttpClient, private authStore: AuthStore) {
  }

  getPagination<T>(url: string): Observable<Pagination<T>> {
    var split = url.split("/api");
    return this.http.get<Pagination<T>>(environment.apiBase+split[split.length-1], {headers: this.authStore.getHeader()})
  }

  postContactUs(data: ContactUs): Observable<any> {
    return this.http.post(apis.contactUs, data, {headers: this.authStore.getHeader()})
  }
}
