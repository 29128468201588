<div class="text-center text-lg-start py-2">
  <!-- Copyright -->
  <div class="container">
    <div class="flex space-between">

      <div class="padding-left hidden">
        <span>Copyright 2023 Arumid, All Right Reserved</span>
      </div>

      <div class="flex a-center center w-links flex-links">
        <a href="contact-us" class="nav-link px-2 text-secondary">Contact Us</a>
        <span>|</span>
        <a href="terms-and-conditions"class="nav-link px-2 text-secondary">Terms & Conditions</a>
        <span>|</span>
        <a href="privacy" class="nav-link ps-2 pe-0 text-secondary">Privacy Policy</a>
      </div>

      <div class="image hidden">
        <img src="../../../assets/images/footer-logo.png" />
      </div>

    </div>
  </div>
  <!-- Copyright -->
</div>
