import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'arumid-expired-token',
  templateUrl: './expiredToken.html',
  styleUrls: ['./expiredToken.component.scss'],
})
export class ExpiredTokenComponent implements OnInit{
  constructor(private _router: Router) {
  }

  ngOnInit(): void {
  }
}
