import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/admin/services/admin.service';
import { MetaLinks, Pagination } from 'src/app/models/common.models';
import { EmailDomain } from 'src/app/models/emailDomains.model';
import { EmailDomainService } from '../services/emailDomain.service';
import { CommonApiService } from '../services/common.service';

@Component({
  selector: 'arumid-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent {
  constructor(private formBuilder: FormBuilder, private emailDomainService: EmailDomainService, private commonApiService: CommonApiService) {}

  emailDomainSearchForm: FormGroup;
  postErrors: any;
  emailDomains: EmailDomain[];
  pagination: MetaLinks[] = null;
  searched: string = "";
  displayedColumns: string[] = ['emailDomains'];

  ngOnInit(): void {
    this.initForm();
    this.searchApiRequest();
  }

  initForm() : void {
    this.emailDomainSearchForm = this.formBuilder.group({
      search: ["", [Validators.required]]
    })
  }

  onSubmit(): void {
    this.searchApiRequest()
  }

  searchApiRequest(): void{
    const searchData = this.emailDomainSearchForm.get('search').value
    this.emailDomainService.getSearchEmailDomains(searchData).toPromise()
      .then((response: Pagination<EmailDomain[]>) => {
        this.setData(response)
      }).catch((error: any) => {
        console.log(error)
      })
  }

  getPage(url: string): void{
    this.commonApiService.getPagination<EmailDomain[]>(url).toPromise()
    .then((response: Pagination<EmailDomain[]>) => {
      this.setData(response)
    })
    .catch((error: any) => {
      console.log(error);
    });
  }

  setData(response: Pagination<EmailDomain[]>){
    if (response.data.length == 0) {
      this.postErrors = "Could not find email domain"
      this.emailDomains = null;
      this.pagination = null;
      this.searched = null;
    } else {
      this.emailDomains = response.data;
      this.postErrors = null;
      this.pagination = response.meta.links;
      this.pagination[0].label = "<< Prev";
      this.pagination[this.pagination.length - 1].label = "Next >>";
    }
  }
}
