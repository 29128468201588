import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { COUNTRIES} from "../../constants";
import {Router} from "@angular/router";
import { RegisterResponse } from 'src/app/models/auth.models';

@Component({
  selector: 'arumid-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit{
  registerForm: FormGroup;
  COUNTRIES = COUNTRIES;

  postErrors: any;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private _router: Router) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.registerForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      password: [null, [Validators.minLength(12), Validators.maxLength(1024), Validators.required]],
      password_confirmation: [null, [Validators.required]],
      country: [this.COUNTRIES[10], [Validators.required]],
      terms_and_conditions: [false, [Validators.requiredTrue]]
    }, {validators: [this.passwordValidator]}
      )

  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password')?.value;
    const passwordConfirm = control.get('password_confirmation')?.value;
    const touched_pass = control.get('password')?.dirty;
    const touched_confirm = control.get('password_confirmation')?.dirty;
    if(password !== passwordConfirm && touched_pass && touched_confirm) {
      return {passwordValid: false};
    }
    return null;
  }

  onSubmit(): void {
    console.log(this.registerForm.value);
    this.authService.postUserRegister(this.registerForm.value).toPromise()
      .then((response: RegisterResponse) => {
        this._router.navigate(['/register_success']);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.error.message);
        console.log(error.error.message === 'UNACCEPTABLE_EMAIL_DOMAIN');
        if(error.error.message === 'UNACCEPTABLE_EMAIL_DOMAIN') {
          console.log("IN");
          this.postErrors = {'email': ['Unacceptable email domain.']};
        } else {
          this.postErrors = error.error.errors;
        }
        console.log(this.postErrors);
      })
  }
}
