<div class="registerCard">
  <h1>Contact Us</h1>
  <div>
    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" class="form">
      <mat-form-field class="formField">
        <mat-label for="name">Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" autocomplete="off" formControlName="email">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="phone">Phone No</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="phone">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="message">Message</mat-label>
        <textarea matInput formControlName="message"></textarea>
      </mat-form-field>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!contactUsForm.valid">Submit</button>
    </form>
  </div>
</div>
