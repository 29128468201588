<div class="modal-container">
  <h1 mat-dialog-title>{{data.publication.title}}</h1>
  <div mat-dialog-content class="modal-container-input">
    <h2>{{data.publication.type == 'J' ? 'Journal' : 'Conference'}}</h2>
  </div>
  <div mat-dialog-content class="modal-container-input">
    <p>Description:</p>
    <p>{{data.publication.description}}</p>
  </div>
  <div *ngIf="data.publication.type == 'C'" mat-dialog-content class="modal-container-input">
    <p>URL:</p>
    <p>{{data.publication.url}}</p>
  </div>
  <div *ngIf="data.publication.type == 'J'" mat-dialog-content class="modal-container-input">
    <p>ISSN: {{data.publication.issn}}</p>
  </div>
  <div mat-dialog-content class="modal-container-input">
    <p>Rank: {{data.rank}}</p>
  </div>
  <div *ngIf="data.publication.tags.length > 0" mat-dialog-content class="modal-container-input">
    <p>Tags:</p>
    <mat-chip-grid #chipGrid class="chip-grid" aria-label="Enter tags">
      <mat-chip-row *ngFor="let tag of data.publication.tags"
        color="primary" highlighted>
        {{tag.tag}}
      </mat-chip-row>
      <input placeholder="New Tag..." [matChipInputFor]="chipGrid"/>
    </mat-chip-grid>
  </div>

  <div mat-dialog-actions class="actions">
    <button mat-stroked-button (click)="closeDialog()">Close</button>
  </div>
</div>
