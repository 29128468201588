<div class="container-main">
  <router-outlet name="header"></router-outlet>
  <arumid-cookie-consent></arumid-cookie-consent>
  <div class="body">
    <router-outlet></router-outlet>
  </div>
  <footer class="mainfooter">
    <router-outlet name="footer"></router-outlet>
  </footer>
</div>
