import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { CommonApiService } from '../services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ThankyouComponent } from '../dialogs/thankyou/thankyou.component';
import { ErrorModalComponent } from '../dialogs/error-dialog/error-modal.component';

@Component({
  selector: 'arumid-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit{
  contactUsForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private commonService: CommonApiService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactUsForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      phone: [null, [Validators.required]],
      message: [null, [Validators.required]]
    })

  }

  onSubmit(): void {
    this.commonService.postContactUs(this.contactUsForm.value).toPromise()
      .then((resp: any) => {
        this.dialog.open(ThankyouComponent, { width: '550px'})
      })
      .catch((error: HttpErrorResponse) => {
        this.dialog.open(ErrorModalComponent, { width: '550px', data: {message: "There was an error submitting your request please try again later"} })

      })
  }
}
