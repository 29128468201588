<div class="registerCard">
  <button class="deletebutton" mat-flat-button color=primary (click)="onDeleteClick()">Delete Account</button>
  <h1>User Profile</h1>
  <div>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="form">
      <!-- <mat-form-field class="example-full-width">
        <mat-label>Favorite food</mat-label>
        <input matInput placeholder="Ex. Pizza" value="Sushi" [formControl]="foodCtrl">
      </mat-form-field> -->
      <mat-form-field class="formField">
        <mat-label for="first_name">First Name</mat-label>
        <input matInput [readonly]="true" type="text" autocomplete="off" formControlName="first_name">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="last_name">Last Name</mat-label>
        <input matInput [readonly]="true" type="text" autocomplete="off" formControlName="last_name">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput [readonly]="true" type="email" formControlName="email">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="old_password">Old Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="old_password">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="new_password">New Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="new_password">
      </mat-form-field>
      <div *ngIf="!(profileForm.getError('passwordValid') == null)"class="error">
        <ul>
          <li>Passwords do not match.</li>
        </ul>
      </div>
      <br>
      <mat-form-field class="formField">
        <mat-label for="new_password_confirmation">New Password Confirmation</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="new_password_confirmation">
      </mat-form-field>
      <div *ngIf="postErrors" class="error">
          <div>{{postErrors}}</div>
      </div>
      <div *ngIf="status" class="success">
        <div>{{status}}</div>
    </div>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!profileForm.valid">Update Password</button>
    </form>
  </div>
</div>
