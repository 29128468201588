import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Router} from "@angular/router";
import { UserService } from '../services/user.service';
import { ProfileService } from "../services/profile.service";
import {AuthStore} from "./../../common/auth/stores/auth.store";
import { ChangePasswordSuccess } from 'src/app/models/auth.models';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent, DialogDataResponse } from '../dialogs/are-you-sure/are-you-sure.component';
import { ErrorModalComponent } from '../dialogs/error-dialog/error-modal.component';

@Component({
  selector: 'arumid-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit{
  profileForm: FormGroup;
  postErrors: any;
  userEmail: any;
  status: any;
  constructor(private formBuilder: FormBuilder,
              private _router: Router,
              private profileService: ProfileService,
              private authStore: AuthStore,
              private userService: UserService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.profileForm = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      old_password: [null, [Validators.required]],
      new_password: [null, [Validators.minLength(12), Validators.maxLength(1024), Validators.required]],
      new_password_confirmation: [null, [Validators.required]]
    }, {validators: [this.passwordValidator]})

    this.profileForm.patchValue({
      first_name: this.authStore.getLoggedInUser().getValue().first_name,
      last_name: this.authStore.getLoggedInUser().getValue().last_name,
      email: this.authStore.getLoggedInUser().getValue().email
    });
  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('new_password')?.value;
    const passwordConfirm = control.get('new_password_confirmation')?.value;
    const touched_pass = control.get('new_password')?.dirty;
    const touched_confirm = control.get('new_password_confirmation')?.dirty;
    if(password !== passwordConfirm && touched_pass && touched_confirm) {
      return {passwordValid: false};
    }
    return null;
  }

  onSubmit(): void {
    this.profileService.postUpdatePassword(this.profileForm.value).toPromise()
      .then((response: ChangePasswordSuccess) => {
        console.log(response);
        this.status = response.status;
        this.postErrors = null;
      })
      .catch((error) => {
        this.postErrors = error.error.message;
      })
  }
  onDeleteClick(): void {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: "550px",
      data: {phrase: "Are you sure you want to delete your account? This action is permanent and cannot be undone! All your publications will be removed once your account is deleted!"}
    });

    dialogRef.afterClosed().subscribe((result: DialogDataResponse) => {
      if(result.isDelete){
        this.userService.deleteCurrentUser().subscribe(
          (response) => {
            this.authStore.setLoggedInUser(null);
            this._router.navigate(['/']);
          },
          (error) => {
            this.dialog.open(ErrorModalComponent, {
              width: "550px",
              data: {message: "There was an error deleting your account please contact an admin"}
            })
          }
        );
      }
    })
  }
}
