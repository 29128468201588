import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { AuthStore } from "../auth/stores/auth.store";
import { Pagination } from "src/app/models/common.models";
import { EmailDomain } from "src/app/models/emailDomains.model";

const emailDomainRequest = {
  getEmailDomainsSearch: (searchData: string) => `${environment.apiBase}/email/search?searchData=${searchData}`,
}

@Injectable({
  providedIn: 'root'
})
export class EmailDomainService {
  constructor(private http: HttpClient, private authStore: AuthStore) {
  }

  getSearchEmailDomains(searchData: string): Observable<Pagination<EmailDomain[]>> {
    return this.http.get<any>(emailDomainRequest.getEmailDomainsSearch(searchData), {headers: this.authStore.getHeader()});
  }
}
