<div>
  <div class="slider-main">
      <div class="slider-row-one">
        <div class="hidden"></div>
          <div class="col-lg flex flex-center flex-direction-col padding-slider-bottom">
            <div class="flex flex-center">
              <h1 class="slider-heading">
                Journal and Conference<br />Ranking by Researchers
              </h1>
            </div>
            <div class="analytics-row flex flex-center default-height flex-center">
              <div class="flex flex-direction-col analytic-details">
                <div class="detail">
                  {{ activeUsers }} Active Users
                </div>
                <div>
                  <button (click)="onClickRankingPage()" class="submit-btn" style="padding: 10px 25px;">
                  Submit Your Rankings
                </button>
                </div>
              </div>
              <div class="flex flex-direction-col analytic-details">
                <div class="detail">
                  {{ totalJournals + totalConferences }} Publications Listed
                </div>
                <div>
                  <button (click)="onClickPublicationPage()" class="submit-btn" style="padding: 10px 25px;">
                    Submit Your Publications
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>

  <div class="container">
    <div class="flex flex-between instruction-container baseline">
      <div class="instruction">
        <div>
          <img src="../../../assets/images/circle1.png" />
          <h5>Register</h5>
          <p class="">
            Register on ARUMID. It's free and only takes a minute, so register
            now!
          </p>
        </div>
      </div>

      <div class="arrow-sec">
        <img src="../../../assets/images/arrow.png" />
      </div>

      <div class="instruction">
        <img src="../../../assets/images/circle2.png" />
        <h5>Use Your ARUMID ID</h5>
        <p class="">
          Login and register your preferences for three journals and conferences
          that you recommend and contribute to.
        </p>
      </div>

      <div class="arrow-sec">
        <img src="../../../assets/images/arrow.png" />
      </div>

      <div class="instruction">
        <img src="../../../assets/images/circle3.png" />
        <h5>Share ARUMID Ranking</h5>
        <p class="">
          Share the ARUMID journal and conference global rankings, rankings for
          your country or for your discipline.
        </p>
      </div>
    </div>
  </div>

  <div class="bg-violet">
    <div class="container">
      <div class="flex flex-direction-col">
        <div class="flex flex-direction-row flex-center">
          <h1 class="ranks-title">Current Ranking</h1>
        </div>
        <div class="flex flex-direction-row flex-center">
          <form [formGroup]="countrySelectForm">
          <mat-form-field appearance="fill" class="sort-rank-country">
            <mat-label>Sort By Country</mat-label>
            <mat-select formControlName="country">
              <mat-option [value]=null>None</mat-option>
              <mat-option *ngFor="let country of countries" [value]="country.country">
                {{country.country}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </form>
        </div>
        <div class="flex flex-direction-row flex-between">
          <div class="flex flex-direction-col flex-center w-100">
            <h2 class="ranking-subtitle">Journals</h2>
            <table class="table table-bordered">
              <tr class="bg-navy text-white">
                <th class="rank">Title</th>
                <th class="score">Rank</th>
              </tr>
              <tr class="data-row" *ngFor="let journal of journalRankings" (click)="openDialog(journal)">
                <td class="title-data">{{journal.title}}</td>
                <td class="rank-data">{{journal.country_rank}}</td>
              </tr>
            </table>
          </div>
          <div class="w-50"></div>
          <div class="flex flex-direction-col flex-center w-100">
            <h2 class="ranking-subtitle">Conferences</h2>
            <table class="table table-bordered">
              <tr class="bg-navy text-white">
                <th class="rank">Title</th>
                <th class="score">Rank</th>
              </tr>
              <tr class="data-row" *ngFor="let conference of conferenceRankings" (click)="openDialog(conference)">
                <td class="title-data">{{conference.title}}</td>
                <td class="rank-data">{{conference.country_rank}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
