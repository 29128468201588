import { Component, HostListener } from '@angular/core';
import {AuthStore} from "./common/auth/stores/auth.store";
import { CONTEXT_MENU } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'arumid';
  constructor(private authStore: AuthStore) {
  }

  ngOnInit(): void{
    let context = this;
    window.addEventListener("beforeunload", function (e) {
      context.authStore.rememberMe()
  });
  }
}
