import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Publication } from 'src/app/models/publication.models';

export interface DialogData {
  publication: Publication
  rank: number
}

@Component({
  selector: 'arumid-publicationdialog',
  templateUrl: './publicationdialog.component.html',
  styleUrls: ['./publicationdialog.component.scss']
})

export class PublicationdialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PublicationdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData){}

  closeDialog() {
    this.dialogRef.close();
  }
}
