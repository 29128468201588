import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router , ActivatedRoute } from "@angular/router";

@Component({
  selector: 'arumid-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss'],
})
export class ResetPasswordComponent implements OnInit{
  resetPasswordForm: FormGroup;

  postErrors: any;
  private token: string;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private _router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.initForm();
    this.token = this.route.snapshot.paramMap.get('token');
  }

  initForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.minLength(12), Validators.maxLength(1024), Validators.required]],
      password_confirmation: [null, [Validators.required]]
    }, {validators: [this.passwordValidator]}
      )

  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password')?.value;
    const passwordConfirm = control.get('password_confirmation')?.value;
    const touched_pass = control.get('password')?.dirty;
    const touched_confirm = control.get('password_confirmation')?.dirty;
    if(password !== passwordConfirm && touched_pass && touched_confirm) {
      return {passwordValid: false};
    }
    return null;
  }

  onSubmit(): void {
    this.authService.postResetPassword({
      email: this.resetPasswordForm.value.email,
      password: this.resetPasswordForm.value.password,
      password_confirmation: this.resetPasswordForm.value.password_confirmation,
      token: this.token
    }).toPromise()
      .then((response: any) => {
        this._router.navigate(['/recovery/reset-password-success']);
      })
      .catch((error) => {
        this.postErrors = error.error.errors;

        if (error.status == 401) {
          this._router.navigate(['recovery/token-expired'])
        }
        console.log(error)
      })
  }
}
