import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './routes/app-routing.module';
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { RegisterComponent } from './common/auth/register/register.component';
import { LoginComponent } from './common/auth/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ArumidMaterialModule} from "./modules/material.module";
import {ContactUsComponent} from "./common/contact-us/contact-us.component";
import { ProfileComponent } from "./common/profile/profile.component";
import { RegisterSuccessComponent } from './common/auth/register/register-success/register-success.component';
import { FormsModule } from '@angular/forms';
import { AboutusComponent } from './common/aboutus/aboutus.component';
import { SubmitPublicationComponent } from './common/publication/submitPublication.component';
import { SubmitRankingPageComponent } from './common/submit-ranking-page/submit-ranking-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ResetPasswordComponent } from './common/auth/password/resetPasswordForm/resetPassword.component';
import { ForgotPasswordComponent } from './common/auth/password/forgotPasswordForm/forgotPassword.component';
import { HomePageComponent } from './common/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureComponent } from './common/dialogs/are-you-sure/are-you-sure.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SuccessModalComponent } from './common/publication/success-modal/success-modal.component';
import { LoginRequiredComponent } from './common/error/login-required/login-required.component';
import { UnauthorisedComponent } from './common/unauthorised/unauthorised.component';
import { ThankyouComponent } from './common/dialogs/thankyou/thankyou.component';
import { PublicationListComponent } from './common/publication/publication-list/publication-list.component';
import { PublicationdialogComponent } from './common/dialogs/publicationdialog/publicationdialog.component';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox'
import { FooterComponent } from './common/footer/footer.component';
import { PrivacyPageComponent } from './common/privacy-page/privacy-page.component';
import { TermsPageComponent } from './common/terms-page/terms-page.component';
import { CookieConsentComponent } from './common/cookie-consent/cookie-consent.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RegisterComponent,
    LoginComponent,
    ContactUsComponent,
    RegisterSuccessComponent,
    RegisterSuccessComponent,
    AboutusComponent,
    SubmitPublicationComponent,
    SubmitRankingPageComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    HomePageComponent,
    ProfileComponent,
    AreYouSureComponent,
    SuccessModalComponent,
    LoginRequiredComponent,
    UnauthorisedComponent,
    ThankyouComponent,
    PublicationdialogComponent,
    PublicationListComponent,
    FooterComponent,
    PrivacyPageComponent,
    TermsPageComponent,
    CookieConsentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ArumidMaterialModule,
    HttpClientModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    NgbModule,
    MatCheckboxModule
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
