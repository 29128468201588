import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import {Router} from "@angular/router";
import { ForgotPasswordRequest, ForgotPasswordSuccessResponse } from 'src/app/models/auth.models';

@Component({
  selector: 'arumid-forgot-password',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.scss'],
})
export class ForgotPasswordComponent implements OnInit{
  forgotPasswordForm: FormGroup;
  postError: any;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private _router: Router) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
    })
  }

  onSubmit(): void {
    this.authService.postForgotPassword(<ForgotPasswordRequest>this.forgotPasswordForm.value).toPromise()
      .then((response: ForgotPasswordSuccessResponse) => {
        this._router.navigate(['/recovery/forgot-password-success'])
      })
      .catch((error: any) => {
        this.postError = error.error.message
      })
  }
}
