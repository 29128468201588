<div class="registerCard">
  <h1>Reset Password</h1>
  <div>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="form">
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" autocomplete="off" formControlName="email">
      </mat-form-field>
      <div *ngIf="postErrors" class="error">
        <ul *ngIf="postErrors.email">
          <li *ngFor="let error of postErrors.email">{{error}}</li>
        </ul>
      </div>
      <mat-form-field class="formField">
        <mat-label for="password">Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="password">
      </mat-form-field>
      <div *ngIf="!(resetPasswordForm.getError('passwordValid') == null)"class="error">
        <ul>
          <li>Passwords do not match.</li>
        </ul>
      </div>
      <mat-form-field class="formField">
        <mat-label for="password_confirmation">Password Confirm</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="password_confirmation">
      </mat-form-field>
      <div *ngIf="postErrors" class="error">
        <ul *ngIf="postErrors.password">
          <li *ngFor="let error of postErrors.password">{{error}}</li>
        </ul>
      </div>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!resetPasswordForm.valid">Change Password</button>
    </form>
  </div>
</div>
