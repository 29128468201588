import { Component } from '@angular/core';

@Component({
  selector: 'arumid-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent {

}
