<div class="container">
    <h1>About Us</h1>
    <p>
        The Australasian Association For Information and Communication
        Technology (AAICT) is the owner and publisher of content on
        this website. This privacy statement applies only to this website.
    </p>
    <p>
        This website contains links to other websites;
        this privacy statement does not apply to those websites.
        AAICT respects users, subscribers and members
        (hereafter referred to as ‘your’ and includes either
        current or prospective users, subscribers or members) rights to
        privacy and the need to protect their personal information.
    </p>
    <p>
        AAICT adheres to the Privacy Act 1988,
        Australia relating to the privacy of your personal information.
    </p>
    <h3>
        SECURITY OF PERSONAL INFORMATION
    </h3>
    <p>
        Your personal information may be collected by AAICT
        when you:
    </p>
    <div class="list">
        <ol>
            <li class="li">provide conference and journal rankings</li>
            <li>provide feedback using online forums and blogs</li>
            <li>submit an enquiry</li>
            <li>subscribe to a newsletter</li>
            <li>access the ‘Subscriber Only’ or ‘Member Only’ section of this website</li>
            <li>make an event booking and/or payment</li>
            <li>submit some type of form i.e. member survey</li>
        </ol>
        <p>
            Information AAICT may collect includes:
        </p>
        <ol>
            <li>name</li>
            <li>affiliation</li>
            <li>personal address details</li>
            <li>contact phone, fax and e-mail address</li>
        </ol>
        <p>
            AAICT may collect your personal information for the
            following reasons:
        </p>
        <ol>
            <li>provision of user, subscriber or member services and advice</li>
            <li>provision of conference or journal rankings</li>
            <li>event registrations and promotion</li>
            <li>compliance with legal obligations - i.e. annual return to Federal or Victorian Government</li>
            <li>direct marketing - user, subscriber or member bulletins, promotion of special offers from AAICT affiliates
                or Corporate Partners</li>
        </ol>
    </div>
    <h3>DISCLOSURE OF MEMBER INFORMATION</h3>
    <p>
        AAICT takes reasonable precautions to protect your personal information,
        including utilising available information technology security
        (firewall and password protected user, subscriber or member information)
    </p>
    <p>
        Your personal information is collected for internal administration
        purposes including, but not limited to, personal
        information, affiliation, conference and journal ranking,
        account payment, event registration,
        compliance with legal obligations, advice of member
        special offers and discounts and membership attraction
        and retention activities.
    </p>
    <p>
        Aggregated conference and journal rankings information
        that has been aggregated using the individual conference
        and journal rankings submitted by users, subscribers or
        members is made available publicly.
        The aggregation process includes the removal of
        individual personal information and any linkages to individual
        data collected and stored on the website.
    </p>
    <p>
        Your personal information will not be sold or distributed
        to third parties (except as received as part of a member
        benefit for principal and corporate partners and associate
        members, as outlined above) for the purposes of direct marketing
        or statistical analysis.
    </p>
    <h3>
        MEMBER FEEDBACK OR ENQUIRY
    </h3>
    <p>
        Any member feedback or enquiry submitted online will only be
        used by the appropriate AAICT staff member and/or applicable
        AAICT contractor to review your comments and answer any
        questions posed. Any personal details will not be disclosed
        to other AAICT staff and/or third parties.
    </p>
    <h3>
        MEMBER ELECTRONIC COMMUNICATION
    </h3>
    <p>
        All AAICT user, subscriber or member information sent to users,
        subscribers or members via e-mail, i.e., bulletins, newsletters
        and general updates are sent to the recipient(s) nominated on
        the “Registration or Membership Application Form” or
        “Subscriber or Member Details Update” or provided through the
        website registration.
    </p>
    <p>
        Any changes made to the nominated recipient(s) need to be
        communicated to the AAICT User, Subscriber or Member
        Services Coordinator via e-mail info@aaict.org.au.
        Requested changes will then be made to the AAICT user,
        subscriber or member database and e-mail distribution list
        simultaneously.
    </p>
    <h3>PRIVACY OFFICER</h3>
    <p>
        You can access member information Agama Group holds with
        respect to you by writing to the Privacy Officer.
        If any of the information Agama Group holds is inaccurate or
        incomplete, please advise the Privacy Officer accordingly.
        The Privacy Officer can be contacted as follows:
    </p>

    <p>
        Privacy Officer
        <br />
        Australasian Association for Information and Communication Technology
        <br />
        PO Box 7015
        <br />
        ASPENDALE VIC 3195
    </p>

    <div id="email_domain_about" class="email-domains">
      <h1>Email domains that we currently accept</h1>
      <form class='email-domain-search' [formGroup]="emailDomainSearchForm" (ngSubmit)="onSubmit()" class="form">
        <input class="search-input" type="text" autocomplete="off" formControlName="search" placeholder="Search by Email Domain">
        <button class="search-button" mat-flat-button color=primary type="submit">Search</button>
      </form>

      <div *ngIf="postErrors" class="error">
        <div>{{postErrors}}</div>
      </div>

      <div *ngIf="emailDomains">
        <table mat-table [dataSource]="emailDomains" class="mat-elevation-z8 userTable">
            <ng-container matColumnDef="emailDomains">
              <th mat-header-cell *matHeaderCellDef> Email Domains</th>
              <td mat-cell *matCellDef="let emailDomain"> {{emailDomain.email_domain}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <div *ngIf="pagination" class="pagination-component">
        <ul *ngFor="let page of pagination" class="pagination">
            <li *ngIf="page.url" (click)="getPage(page.url)" class="pagination-item {{page.active}}">
                <a>{{page.label}}</a>
            </li>
        </ul>
    </div>
  </div>
</div>
