<header class="header">
  <div class="site-logo">
    <a class="logo" routerLinkActive="active" routerLink="/home" [routerLinkActiveOptions]="{exact: true}"><img src="/assets/images/logo.png"/></a>
  </div>
  <input class="menu-btn" type="checkbox" id="menu-btn" />
  <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
  <ul class="menu">
    <li><a *ngIf="!(loggedInUser$ | async)" routerLinkActive="active" routerLink="/register" [routerLinkActiveOptions]="{exact: true}">Register</a></li>
    <li><a *ngIf="!(loggedInUser$ | async)" routerLinkActive="active" routerLink="/login" [routerLinkActiveOptions]="{exact: true}">Login</a></li>
    <li><a *ngIf="loggedInUser$ | async" (click)="logOut()" routerLink="/">Logout</a></li>
    <li><a *ngIf="loggedInUser$ | async" routerLinkActive="active"  routerLink="/profile" [routerLinkActiveOptions]="{exact: true}">Profile</a></li>
    <li><a routerLinkActive="active" routerLink="/publication-list" [routerLinkActiveOptions]="{exact: true}">Search</a></li>
    <li><a routerLinkActive="active" routerLink="/contact-us" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
    <li><a routerLinkActive="active" routerLink="/aboutus" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
    <li><a *ngIf="(loggedInUser$ | async)?.type == 'ADMIN'" routerLinkActive="active" routerLink="/admin/dashboard" [routerLinkActiveOptions]="{exact: true}">Admin</a></li>
  </ul>
</header>
