import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'arumid-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit{
  showBanner: boolean = false;

  ngOnInit(): void {
    const consent = localStorage.getItem('cookie_consent');
    if(!consent) {
      this.showBanner = true;
    }
  }

  acceptConsent() {
    localStorage.setItem('cookie_consent', 'true');
    this.showBanner = false;
  }
}
