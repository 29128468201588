import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Publication, Tag} from "../../../models/publication.models";
import {MatTableDataSource} from "@angular/material/table";
import {MetaLinks, Pagination} from "../../../models/common.models";
import {AdminService} from "../../../admin/services/admin.service";
import {CommonApiService} from "../../services/common.service";
import {saveAs} from "file-saver";
import { Rank } from 'src/app/models/ranking.models';
import { PublicationService } from '../../services/publication.service';
import { PublicationdialogComponent } from '../../dialogs/publicationdialog/publicationdialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'arumid-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: ['./publication-list.component.scss']
})
export class PublicationListComponent implements OnInit{
  publicationSearchForm: FormGroup;
  postErrors: any;
  publications: Publication[];
  dataSource: MatTableDataSource<Publication> = new MatTableDataSource([]);
  pagination: MetaLinks[] = null;
  searched: string = null;
  displayedColumns: string[] = ['title', 'rank','type'];

  constructor(private formBuilder: FormBuilder, private adminService: AdminService, private commonApiService: CommonApiService, private publicationService: PublicationService, private dialog: MatDialog) {}
  ngOnInit(): void {
    this.initForm();
    this.searchApiRequest();
  }

  initForm(): void {
    this.publicationSearchForm = this.formBuilder.group({
      searchData: [""],
      status: ["APPROVED"]
    });
  }

  onSubmit(): void {
    this.searchApiRequest();
  }

  searchApiRequest() {
    const searchData = this.publicationSearchForm.get('searchData').value;
    const filter = this.publicationSearchForm.get('status').value;
    this.adminService.getPublicationSearch(searchData, filter).toPromise()
      .then((response: Pagination<Publication[]>) => {
        this.setData(response);
      }).catch((error: any) => {
      console.log(error)
    });
  }

  setData(response: Pagination<Publication[]>){
    if(response.data.length == 0) {
      this.postErrors = "No Publications Found.";
      this.publications = null;
      this.dataSource = new MatTableDataSource<Publication>(null);
      this.pagination = null;
      this.searched = null;
    } else {
      this.publications = response.data;
      this.dataSource = new MatTableDataSource<Publication>(response.data);
      this.postErrors = null;
      this.pagination = response.meta.links;
      this.pagination[0].label = "<< Prev";
      this.pagination[this.pagination.length -1].label = "Next >>";
    };
  }

  getPage(url: string) {
    this.commonApiService.getPagination<Publication[]>(url).toPromise()
      .then((response: Pagination<Publication[]>) => {
        this.setData(response)
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  openDialog(publication: Publication) {
    this.publicationService.getPublicationById(publication.id).subscribe((data: any) => {
      console.log(data.data)
      const dialogRef = this.dialog.open(PublicationdialogComponent, {
        width: '550px',
        data: {publication: data.data, rank: publication.rank}
      });
    });
  }
}
