import {Component, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { AuthService } from "../services/auth.service";
import {Router} from "@angular/router";
import {AuthStore} from "../stores/auth.store";
import { LogInResponse } from 'src/app/models/auth.models';

@Component({
  selector: 'arumid-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit{
  loginForm: FormGroup;
  postErrors: any;
  remember = false;
  postErrorMessage: string;
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private _router: Router,
              private authStore: AuthStore) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]]
    })

  }

  onSubmit(): void {
    console.log(this.loginForm.value);
    this.authService.postUserLogin(this.loginForm.value).toPromise()
      .then((response: LogInResponse) => {
        console.log(response);
        localStorage.setItem('token', JSON.stringify(response.token));
        localStorage.setItem('currentUser', JSON.stringify(response.user));
        localStorage.setItem('remember', JSON.stringify(this.remember));
        this.authStore.setLoggedInUser(response.user);
        this._router.navigate(['/']);
      })
      .catch((error) => {
        if(error.error.message === 'UNVERIFIED'){
          this._router.navigate(['/register_success'])
        }

        this.postErrors = error.error.message;

        if(this.postErrors === 'BAD_CREDENTIALS') {
          this.postErrorMessage = 'Invalid email or password.'
        }
        if(this.postErrors === 'ACCOUNT_DISABLED') {
          this.postErrorMessage = 'Account is disabled. Please contact domains@arumid.org'
        }
      })
  }
}
