<div class="registerCard">
  <h1>User Login</h1>
  <div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form">
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" autocomplete="off" formControlName="email">
      </mat-form-field>
      <br>
      <mat-form-field class="formField">
        <mat-label for="password">Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="password">
      </mat-form-field>
      <mat-checkbox color="primary" [checked]="remember" (change)="remember = !remember">Remember Me</mat-checkbox>
      <div *ngIf="postErrors" class="error">
        <div>{{postErrorMessage}}</div>
      </div>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!loginForm.valid">Login</button>
      <div class="forgotPasswordLink">
        <a href="/recovery/forgot-password">Forgot Password?</a>
      </div>
    </form>
  </div>
</div>
