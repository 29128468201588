import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'arumid-forgot-password-success',
  templateUrl: './forgotPasswordSuccess.html',
  styleUrls: ['./forgotPasswordSuccess.component.scss'],
})
export class ForgotPasswordSuccessComponent implements OnInit{
  constructor(private _router: Router) {
  }

  ngOnInit(): void {
  }
}
