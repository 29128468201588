import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthStore} from "../auth/stores/auth.store";
import { User } from 'src/app/models/user.models';

@Component({
  selector: 'arumid-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  private subscription = new Subscription();
  loggedInUser$: BehaviorSubject<User>;
  constructor(private authStore: AuthStore) {
  }

  ngOnInit(): void {
    this.loggedInUser$ = this.authStore.getLoggedInUser();
  }

  logOut() {
    this.authStore.logoutUser();
  }
}
