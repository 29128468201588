<div class="submit-desc">
    <h2>Submit Ranking</h2>
    <p>
        Contribute to Arumid by submitting your rankings below.<br>
        You are required to pick your top 3 preferences for both Journals and Conferences that are listed on this site.
        </p>
        <h2>How do I submit?</h2>
        <p>
        Simply search for your preferences in the search bars and add them to your rankings on the left.<br>
        You can change the rank ordering by dragging and dropping your selected preferences.<br>
        Once finished press the submit button at the bottom of the page.
        </p>
        <p>
        Once your rankings have been submitted you must wait a number of days before you are allowed to change your preferences.
        </p>
  <div *ngIf="!(isAllRanked && validLastRanked)" class="error">
    <ul>
      <li *ngIf="!isAllRanked">All 3 rankings needed for Journal and Conference</li>
      <li *ngIf="!validLastRanked">You've already ranked within the last 30 days. <br>Please wait {{30 - lastSubmitted}} more days.</li>
    </ul>
  </div>
  <button class="search-button" mat-flat-button color=primary type="submit" (click)="onSubmitRanking()" [disabled]="!(isAllRanked && validLastRanked)">Submit Ranking</button>
</div>
<div class="container">
  <div class="card">
    <h1>Journal Ranking</h1>
    <form [formGroup]="journalsSearchForm" (ngSubmit)="onSubmitJournal()" class="form">
      <input class="search-input" type="text" autocomplete="off" formControlName="searchData" placeholder="Search by Title, ISSN, URL or tags">
      <button class="search-button" mat-flat-button color=primary type="submit" [disabled]="!journalsSearchForm.valid">Search</button>
    </form>
    <div>
      <div class="example-container">
        <h2>Journals</h2>

        <div
          cdkDropList
          #journalsList="cdkDropList"
          [cdkDropListData]="journals"
          [cdkDropListConnectedTo]="[journalsRankingList]"
          class="example-list"
          (cdkDropListDropped)="drop($event, 'journals')">
          <div class="example-box" *ngFor="let journal of journals" cdkDrag>
            {{journal.title}} {{journal.issn}}
          </div>
        </div>
      </div>

      <div class="example-container">
        <h2>Ranking</h2>

        <div
          cdkDropList
          #journalsRankingList="cdkDropList"
          [cdkDropListData]="journalRankings"
          [cdkDropListConnectedTo]="[journalsList]"
          class="example-list"
          (cdkDropListDropped)="drop($event, 'journalRankings')">
          <div class="example-box" *ngFor="let journal of journalRankings;index as c" cdkDrag [cdkDragDisabled]="!journal.id">
            {{c+1}} {{journal.title}} {{journal.issn}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <h1>Conference Ranking</h1>
    <form [formGroup]="conferenceSearchForm" (ngSubmit)="onSubmitConference()" class="form">
      <input class="search-input" type="text" autocomplete="off" formControlName="searchData" placeholder="Search by Title, ISSN, URL or tags">
      <button class="search-button" mat-flat-button color=primary type="submit" [disabled]="!conferenceSearchForm.valid">Search</button>
    </form>
    <div>
      <div class="example-container">
        <h2>Conferences</h2>

        <div
          cdkDropList
          #conferenceList="cdkDropList"
          [cdkDropListData]="conferences"
          [cdkDropListConnectedTo]="[conferencesRankingList]"
          class="example-list"
          (cdkDropListDropped)="drop($event, 'conference')">
          <div class="example-box" *ngFor="let conference of conferences" cdkDrag>
            {{conference.title}} {{conference.issn}}
          </div>
        </div>
      </div>

      <div class="example-container">
        <h2>Ranking</h2>

        <div
          cdkDropList
          #conferencesRankingList="cdkDropList"
          [cdkDropListData]="conferenceRankings"
          [cdkDropListConnectedTo]="[conferenceList]"
          class="example-list"
          (cdkDropListDropped)="drop($event, 'conferenceRankings')">
          <div class="example-box" *ngFor="let conference of conferenceRankings;index as c" cdkDrag [cdkDragDisabled]="!conference.id">
            {{c+1}} {{conference.title}} {{conference.issn}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
