

<div class="card">
  <h1>Submit a Publication</h1>
  <p>
    You may enter a submission for a publication you would like to see be ranked
    in Arumid
  </p>
  <div class="container">
    <div class="item">
      <h2>Journal</h2>
    </div>
    <div class="item">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="isChecked"
          [ngClass]="{ 'switch-on': isChecked && getCorrectValidator(), 'switch-off': !isChecked && getCorrectValidator() }" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="item">
      <h2>Conference</h2>
    </div>
  </div>

  <div>
    <form [formGroup]="publicationForm" class="input-form-box" (ngSubmit)="onSubmit()">
      <div>
        <input mat-input formControlName="title" type="text" class="form-group" id="title" placeholder="Title*" name="title"
          required />
      </div>
      <div *ngIf="isChecked">
        <input mat-input formControlName="url" type="text" class="form-group" id="url" placeholder="URL*" name="url"
          required />
      </div>
      <div *ngIf="!isChecked">
        <input mat-input [maxLength]="8" formControlName="issn" type="text" class="form-group-issn" id="issn" placeholder="ISSN*" name="issn"
          required />
      </div>
      <div class="issn-label-div" *ngIf="!isChecked">
        <label class="issn-label">ISSN must be 8 charcters long</label>
      </div>
      <div class="text-area">
        <div>
          <textarea mat-input [maxLength]="maxChars" formControlName="description" type="text" class="form-group-text" id="description"
          placeholder="Description...*" name="description" [(ngModel)]="description" required></textarea>
        </div>
        <div class="count-div">
          <mat-hint class="count" >{{description.length}} / {{maxChars}}</mat-hint>
        </div>
      </div>

      <mat-form-field class="tag-list" appearance="fill">
        <mat-chip-grid #chipGrid class="chip-grid" aria-label="Enter tags">
          <mat-chip-row *ngFor="let tag of tags" (removed)="remove(tag)" [editable]="true"
            (edited)="edit(tag, $event)" [aria-description]="'press enter to edit ' + tag"
            color="primary" highlighted>
            {{tag}}
            <button matChipRemove [attr.aria-label]="'remove ' + tag">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input placeholder="New Tag..." [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)" />
        </mat-chip-grid>
      </mat-form-field>
      <button mat-raised-button class="submit-button" color="primary" type="submit" [disabled]="!publicationForm.valid">Submit</button>
    </form>
  </div>
</div>
