import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'arumid-reset-password-success',
  templateUrl: './resetPasswordSuccess.html',
  styleUrls: ['./resetPasswordSuccess.component.scss'],
})
export class ResetPasswordSuccessComponent implements OnInit{
  constructor(private _router: Router) {
  }

  ngOnInit(): void {
  }
}
