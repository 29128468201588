import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { AdminService } from 'src/app/admin/services/admin.service';

export interface DialogData {
  phrase: string
}

export interface DialogDataResponse {
  isDelete: boolean
}

@Component({
  selector: 'arumid-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.scss']
})

export class AreYouSureComponent {
  constructor(
    private adminService: AdminService,
    public dialogRef: MatDialogRef<AreYouSureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close(<DialogDataResponse>{isDelete: false});
  }

  onDelete(): void {
    this.dialogRef.close(<DialogDataResponse>{isDelete: true});
  }
}
