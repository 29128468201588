import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import { Pagination } from "src/app/models/common.models";
import { ActiveUsersCount, ConferenceCount, InactiveUserCount, JournalCount } from "src/app/models/analytics.models";
import { User } from "src/app/models/user.models";
import { AuthStore } from "src/app/common/auth/stores/auth.store";
import { EmailDomain, PostEmailDomainRequest } from "src/app/models/emailDomains.model";
import { Publication } from "src/app/models/publication.models";

const adminDashboardRequests = {
  getTotalActiveUsers: `${environment.apiBase}/activeUserCount`,
  getTotalInactiveUsers: `${environment.apiBase}/inactiveUserCount`,
  getTotalJournals: `${environment.apiBase}/journalCount`,
  getTotalConferences: `${environment.apiBase}/conferencesCount`
}
const userSearchRequests = {
  postUserSearch: (searchData: string) => `${environment.apiBase}/admin/user/search?searchData=${searchData}`,
  disableUser: `${environment.apiBase}/admin/user/disable`,
  activateUser: `${environment.apiBase}/admin/user/activate`,
  export: `${environment.apiBase}/admin/users/export`,
  updateUserType: `${environment.apiBase}/admin/user/update-usertype/`
}
const publicationRequests = {
  getPublicationSearch: (searchData: string, status: string) => `${environment.apiBase}/publications/search/${status}?search=${searchData}`,
  putApprovePublication: `${environment.apiBase}/publications/approve`,
  deletePublication: (publicationID: number) => `${environment.apiBase}/publications/${publicationID}`,
  export: `${environment.apiBase}/admin/publications/export`,
  updatePublication: (publicationID: number) => `${environment.apiBase}/publications/update/${publicationID}`
}

const emailDomainRequest = {
  postAddEmailDomain: `${environment.apiBase}/email/domains`,
  deleteEmailDomain: (id: string) => `${environment.apiBase}/email/domains/${id}`,
  putEmailDomain: (id: string) => `${environment.apiBase}/email/domains/${id}`,
  export: `${environment.apiBase}/admin/email-domains/export`
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient, private authStore: AuthStore) {
  }

  getTotalActiveUsers(): Observable<ActiveUsersCount> {
    return this.http.get<ActiveUsersCount>(adminDashboardRequests.getTotalActiveUsers, {headers: this.authStore.getHeader()});
  }

  getTotalInactiveUsers(): Observable<InactiveUserCount> {
    return this.http.get<InactiveUserCount>(adminDashboardRequests.getTotalInactiveUsers, {headers: this.authStore.getHeader()});
  }

  getTotalJournals(): Observable<JournalCount> {
    return this.http.get<JournalCount>(adminDashboardRequests.getTotalJournals, {headers: this.authStore.getHeader()});
  }

  getTotalConferences(): Observable<ConferenceCount> {
    return  this.http.get<ConferenceCount>(adminDashboardRequests.getTotalConferences, {headers: this.authStore.getHeader()});
  }

  getUserSearch(searchData: string): Observable<Pagination<User[]>> {
    return this.http.get<Pagination<User[]>>(userSearchRequests.postUserSearch(searchData), {headers: this.authStore.getHeader()});
  }

  getPublicationSearch(searchData: string, status: string): Observable<any> {
    return this.http.get<any>(publicationRequests.getPublicationSearch(searchData, status), {headers: this.authStore.getHeader()});
  }

  disableUser(userId: string): Observable<any> {
    const url = `${userSearchRequests.disableUser}/${userId}`;
    return this.http.get<any>(url, {headers: this.authStore.getHeader()});
  }

  activateUser(userId: string): Observable<any> {
    const url = `${userSearchRequests.activateUser}/${userId}`;
    return this.http.get<any>(url, {headers: this.authStore.getHeader()});
  }

  updateUserType(userId: string): Observable<any> {
    const url = `${userSearchRequests.updateUserType}${userId}`;
    return this.http.get<any>(url, {headers: this.authStore.getHeader()});
  }

  putApprovePublication(publicationID: number): Observable<any> {
    return this.http.put<any>(publicationRequests.putApprovePublication, {id: publicationID}, {headers: this.authStore.getHeader()});
  }

  deletePublication(publicationID: number): Observable<any> {
    return this.http.delete(publicationRequests.deletePublication(publicationID), {headers: this.authStore.getHeader()});
  }

  postAddDomain(data: PostEmailDomainRequest): Observable<any> {
    return this.http.post<any>(emailDomainRequest.postAddEmailDomain, data, {headers: this.authStore.getHeader()});
  }

  deleteEmailDomain(id: string): Observable<any> {
    return this.http.delete(emailDomainRequest.deleteEmailDomain(id), {headers: this.authStore.getHeader()})
  }

  putEmailDomian(domain: EmailDomain): Observable<any> {
    return this.http.put(emailDomainRequest.putEmailDomain(String(domain.id)), <PostEmailDomainRequest>{email_domain: domain.email_domain}, {headers: this.authStore.getHeader()});
  }

  getUserExport(): Observable<any> {
    return this.http.get(userSearchRequests.export, {headers: this.authStore.getHeader(), responseType: 'text', observe: 'response'});
  }

  getPublicationExport(): Observable<any> {
    return this.http.get(publicationRequests.export, {headers: this.authStore.getHeader(), responseType: 'text', observe: 'response'});
  }

  getEmailDomainExport(): Observable<any> {
    return this.http.get(emailDomainRequest.export, {headers: this.authStore.getHeader(), responseType: 'text', observe: 'response'});
  }
  updatePublication(publication: Publication): Observable<any> {
    return this.http.put(publicationRequests.updatePublication(publication.id), publication, {headers: this.authStore.getHeader(), responseType: 'text', observe: 'response'});
  }
}
