import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import { ForgotPasswordRequest, LogInRequest, LogInResponse, RegisterRequest, RegisterResponse, ResetPasswordRequest } from "src/app/models/auth.models";
import { AuthStore } from "../stores/auth.store";

const authRequests = {
  postUserRegister: `${environment.apiBase}/register`,
  postUserLogin: `${environment.apiBase}/login`,
  postForgotPassword: `${environment.apiBase}/forgot-password`,
  postResetPassword: `${environment.apiBase}/reset-password`,
  logoutUser: `${environment.apiBase}/user/logout`
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private authStore: AuthStore ) {
  }

  postUserRegister(data: RegisterRequest): Observable<any> {
    return this.http.post<RegisterResponse>(authRequests.postUserRegister, data, {headers: this.authStore.getHeader()});
  }

  postUserLogin(data: LogInRequest): Observable<any> {
    return this.http.post<any>(authRequests.postUserLogin, data, {headers: this.authStore.getHeader()});
  }

  postForgotPassword(data: ForgotPasswordRequest): Observable<any> {
    return this.http.post<any>(authRequests.postForgotPassword, data, {headers: this.authStore.getHeader()});
  }

  postResetPassword(data: ResetPasswordRequest): Observable<any> {
    return this.http.post<any>(authRequests.postResetPassword, data, {headers: this.authStore.getHeader()});
  }

  logout(): Observable<any> {
    return this.http.get<any>(authRequests.logoutUser, {headers: this.authStore.getHeader()});
  }
}
