import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Publication} from "../../models/publication.models";
import {PublicationService} from "../services/publication.service";
import {Pagination} from "../../models/common.models";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Router} from "@angular/router";
import {AuthStore} from "../auth/stores/auth.store";
import {User} from "../../models/user.models";
import {BehaviorSubject, Subscription} from "rxjs";
@Component({
  selector: 'app-submit-ranking-page',
  templateUrl: './submit-ranking-page.component.html',
  styleUrls: ['./submit-ranking-page.component.scss']
})
export class SubmitRankingPageComponent implements OnInit{
    journalsSearchForm: FormGroup;
    journals: Publication[];
    journalRankings: Publication[] = [{}, {}, {}];

    conferenceSearchForm: FormGroup;
    conferences: Publication[];
    conferenceRankings: Publication[] = [{}, {}, {}];

    isAllRanked: boolean = false;
    validLastRanked: boolean = false;
    lastSubmitted: number = 0;
    user: BehaviorSubject<User>;
    private subscription = new Subscription();
    constructor(private formBuilder: FormBuilder, private publicationService: PublicationService, private _router: Router, private authStore: AuthStore) {
    }

  ngOnInit(): void {
      this.user = this.authStore.getLoggedInUser();
      this.initSubscription();
      this.initJournalsForm();
      this.initConferenceForm();
  }

  initSubscription() {
      this.subscription.add(
        this.user.subscribe((user: User) => {
          console.log(user);
          if(user){
            if(user.last_ranked !== null){
              const currentDate = new Date();
              const lastRanked = new Date(user.last_ranked);
              const difference = currentDate.getTime() - lastRanked.getTime();
              const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
              this.lastSubmitted = differenceInDays;
              if(differenceInDays > 30) {
                this.validLastRanked = true;
              } else {
                this.validLastRanked = false;
              }
            } else {
              this.validLastRanked = true;
            }
          }
        })
      )
  }

  initJournalsForm(): void {
      this.journalsSearchForm = this.formBuilder.group({
        searchData: [""]
      });
  }

  initConferenceForm(): void {
      this.conferenceSearchForm = this.formBuilder.group({
        searchData: [""]
      });
  }

  onSubmitJournal(): void {
    const searchData = this.journalsSearchForm.get('searchData').value;
    this.publicationService.getApprovedJournalsSearch(searchData).toPromise()
      .then((response: Pagination<Publication[]>) => {
        this.journals = response.data;
      })
  }

  onSubmitConference(): void {
    const searchData = this.conferenceSearchForm.get('searchData').value;
    this.publicationService.getApprovedConferencesSearch(searchData).toPromise()
      .then((response: Pagination<Publication[]>) => {
        this.conferences = response.data;
      })
  }

  onSubmitRanking(): void {
    const rankings = {
      "journals": {
        "publication_id_one": this.journalRankings[0].id,
        "publication_id_two": this.journalRankings[1].id,
        "publication_id_three": this.journalRankings[2].id
      },
      "conferences": {
        "publication_id_one": this.conferenceRankings[0].id,
        "publication_id_two": this.conferenceRankings[1].id,
        "publication_id_three": this.conferenceRankings[2].id
      }
    };
    this.publicationService.postRankings(rankings).toPromise()
      .then((response: any) => {
        this.authStore.refreshUser();
        this._router.navigate(['/']);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  drop(event: CdkDragDrop<Publication[]>, droppedOn: 'journals' | 'journalRankings' | 'conference' | 'conferenceRankings') {
      let currentIndex;
      if(event.currentIndex > 2) {
        currentIndex = 2;
      } else {
        currentIndex = event.currentIndex;
      }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if(droppedOn === 'journals' || droppedOn === 'journalRankings') {
        if (droppedOn === 'journals') {
          //moved back to journals, need to re add placeholder
          this.journalRankings.push({});
        } else {
          // moved to journalRankings, need to remove the placeholder;

          const ranking = this.journalRankings[currentIndex];
          if (!ranking.id) {
            // dropped on blank, remove it
            this.journalRankings.splice(currentIndex, 1);
          } else {
            //dropped on journal, swap journals
            this.journals.push(ranking);
            this.journalRankings.splice(currentIndex, 1);
          }
        }
      } else {
        if (droppedOn === 'conference') {
          //moved back to journals, need to re add placeholder
          this.conferenceRankings.push({});
        } else {
          // moved to journalRankings, need to remove the placeholder;

          const ranking = this.conferenceRankings[currentIndex];
          if (!ranking.id) {
            // dropped on blank, remove it
            this.conferenceRankings.splice(currentIndex, 1);
          } else {
            //dropped on journal, swap journals
            this.conferences.push(ranking);
            this.conferenceRankings.splice(currentIndex, 1);
          }
        }
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.checkRankingsMade();
  }

  checkRankingsMade(): void {
      let isAllRanked = true;
      this.journalRankings.forEach((publication: Publication) => {
        if(publication.id == null){
          isAllRanked = false;
        }
      });

      this.conferenceRankings.forEach((publication: Publication) => {
        if(publication.id == null) {
          isAllRanked = false;
        }
      })

      if(isAllRanked) {
        this.isAllRanked = true;
      } else {
        this.isAllRanked = false;
      }
  }
}
