import { Component } from '@angular/core';

@Component({
  selector: 'arumid-already-success',
  templateUrl: './alreadySuccess.component.html',
  styleUrls: ['./alreadySuccess.component.scss']
})
export class AlreadySuccessComponent {

}
