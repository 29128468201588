import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {RegisterComponent} from "../common/auth/register/register.component";
import {LoginComponent} from "../common/auth/login/login.component";
import {SuccessComponent} from "../common/auth/emailVerification/success/success.component";
import {AlreadySuccessComponent} from "../common/auth/emailVerification/alreadySuccess/alreadySuccess.component";
import {HeaderComponent} from "../common/header/header.component";
import {HeaderAdminComponent} from "../admin/header/header.component";
import {ContactUsComponent} from "../common/contact-us/contact-us.component";
import {RegisterSuccessComponent} from "../common/auth/register/register-success/register-success.component";
import { AboutusComponent } from '../common/aboutus/aboutus.component';
import { SubmitPublicationComponent } from '../common/publication/submitPublication.component';
import { SubmitRankingPageComponent } from '../common/submit-ranking-page/submit-ranking-page.component';
import { ResetPasswordComponent } from '../common/auth/password/resetPasswordForm/resetPassword.component';
import { ForgotPasswordComponent } from '../common/auth/password/forgotPasswordForm/forgotPassword.component';
import { ForgotPasswordSuccessComponent } from '../common/auth/password/forgotPasswordForm/success/forgotPasswordSuccess.component';
import { ResetPasswordSuccessComponent } from '../common/auth/password/resetPasswordForm/success/resetPasswordSuccess.component';
import { ExpiredTokenComponent } from '../common/auth/password/resetPasswordForm/expired/expiredToken.component';
import { ProfileComponent } from "../common/profile/profile.component";
import { HomePageComponent } from '../common/home/home.component';
import { LoginRequiredComponent } from '../common/error/login-required/login-required.component';
import { AuthGuard } from '../common/auth/guard/auth.guard';
import {UnauthorisedComponent} from "../common/unauthorised/unauthorised.component";
import {PublicationListComponent} from "../common/publication/publication-list/publication-list.component";
import { FooterComponent } from '../common/footer/footer.component';
import { PrivacyPageComponent } from '../common/privacy-page/privacy-page.component';
import { TermsPageComponent } from '../common/terms-page/terms-page.component';


const routes: Routes = [
  { path: 'admin', loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule), component: HeaderAdminComponent},
  {
    path: '',
    children: [
      { path: '',component: HeaderComponent, outlet: 'header'},
      { path: '', component: HomePageComponent, pathMatch: 'full'},
      { path: 'home', component: HomePageComponent, pathMatch: 'full'},
      { path: 'register', component: RegisterComponent, pathMatch: 'full'},
      { path: 'login', component: LoginComponent, pathMatch: 'full'},
      { path: 'recovery/reset-password/:token', component: ResetPasswordComponent, pathMatch: 'full'},
      { path: 'recovery/forgot-password', component: ForgotPasswordComponent, pathMatch: 'full'},
      { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full'},
      { path: 'email/verify/verified', component: SuccessComponent, pathMatch: 'full'},
      { path: 'email/verify/already-verified', component: AlreadySuccessComponent, pathMatch: 'full'},
      { path: 'register_success', component: RegisterSuccessComponent, pathMatch: 'full'},
      { path: 'aboutus', component: AboutusComponent, pathMatch: 'full'},
      { path: 'error/login', component: LoginRequiredComponent, pathMatch: 'full'},
      { path: 'submit-publication-page', canActivate: [AuthGuard], component: SubmitPublicationComponent, pathMatch: 'full'},
      { path: 'submit-ranking-page',  canActivate: [AuthGuard], component: SubmitRankingPageComponent, pathMatch: 'full'},
      { path: 'recovery/forgot-password-success', component: ForgotPasswordSuccessComponent, pathMatch: 'full'},
      { path: 'recovery/reset-password-success', component: ResetPasswordSuccessComponent, pathMatch: 'full'},
      { path: 'recovery/token-expired', component: ExpiredTokenComponent, pathMatch: 'full'},
      { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent, pathMatch: 'full'},
      { path: 'unauthorised', component: UnauthorisedComponent, pathMatch: 'full'},
      { path: 'publication-list', component: PublicationListComponent, pathMatch: 'full'},
      { path: 'privacy', component: PrivacyPageComponent, pathMatch: 'full'},
      { path: 'terms-and-conditions', component: TermsPageComponent, pathMatch: 'full'},
      { path: '',component: FooterComponent, outlet: 'footer'},
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule{}
