<div class="registerCard">
  <h1>Trouble logging in?</h1>
  <h3>
    To reset your password, enter the email address associated with your Arumid account
  </h3>
  <div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="form">
      <mat-form-field class="formField">
        <mat-label for="email">Email</mat-label>
        <input matInput type="email" autocomplete="off" formControlName="email">
      </mat-form-field>
      <div *ngIf="postError" class="error">
          <p>{{postError}}</p>
      </div>
      <br>
      <button  mat-flat-button color=primary type="submit" [disabled]="!forgotPasswordForm.valid">Continue</button>
    </form>
  </div>
</div>
